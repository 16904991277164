import request from '@/api/request.js'

//获取住房列表
export function getZhuFangListFn (data){
  return request({
    url:'/village/house',
    method:'get',
    params:data
  })
}
//新增住房
export function addZhuFangFn (data){
  return request({
    url:'/village/house/add',
    method:'post',
    data:data
  })
}
//获取住房详情
export function getZhuFangDetailsFn (data){
  return request({
    url:'/village/house/' + data.id,
    method:'get',
    params:data
  })
}
//编辑住房
export function editZhuFangFn (data){
  return request({
    url:'/village/house/' + data.id + '/update',
    method:'post',
    data:data
  })
}
//删除住房
export function deleteZhuFangFn (data){
  return request({
    url:'/village/house/'+ data.id +'/delete',
    method:'post',
    data:data
  })
}

//获取住房下人员列表
export function getRenYuanInZhuFangListFn (data){
  return request({
    url:'/village/house/'+ data.id +'/member',
    method:'get',
    params:data
  })
}