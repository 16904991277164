import request from '@/api/request.js'

//获取住户列表
export function getZhuHuListFn (data){
  return request({
    url:'/village/occupant',
    method:'get',
    params:data
  })
}

//新增住户
export function addZhuHuFn (data){
  return request({
    url:'/village/occupant/add',
    method:'post',
    data:data
  })
}
//获取住户详情
export function getZhuHuDetailsFn (data){
  return request({
    url:'/village/occupant/' + data.id,
    method:'get',
    params:data
  })
}
//编辑住户
export function editZhuHuFn (data){
  return request({
    url:'/village/occupant/'+ data.id +'/update',
    method:'post',
    data:data
  })
}
//删除住户
export function deleteZhuHuFn (data){
  return request({
    url:'/village/occupant/' + data.id +   '/delete',
    method:'post',
    data:data
  })
}
