<template>
	<div class='container'>
		<div class="title">
      <span class="inner_title">房屋下人员列表</span>
    </div>
    <div class="xiaoqu-info">
      <div v-if="zhuHuInfo.other">{{zhuHuInfo.other.house_info}}</div>
    </div>
    <div class="top-box">
			<div class="top-two">
				<el-button type="success" @click="goToAdd">添加成员</el-button>
			</div>
		</div>
    <div class="cont_container" style="padding-top:20px">
      <template>
        <el-table class="table"  :element-loading-text="loadingText" element-loading-spinner="el-icon-loading"
        :element-loading-background="loadingColor" :data="zhuHuList" :header-cell-style="TableTitleBackColor"
        :row-style="tableCss" :highlight-current-row="true" :cell-style="cellStyle">
          <el-table-column label="序号" type="index" width="100">
          </el-table-column>
          <el-table-column prop="user_name" label="姓名">
          </el-table-column>
          <el-table-column prop="sex" label="性别">
          </el-table-column>
          <el-table-column prop="type_txt" label="身份类型">
          </el-table-column>
          <el-table-column prop="id_number" label="身份证号">
          </el-table-column>
          <el-table-column prop="mobile" label="手机号">
          </el-table-column>
          <el-table-column  label="操作" width="200">
            <template slot-scope="scope">
              <el-button type="primary"  size="mini" @click="goToEditPage(scope.row)">编辑</el-button>
              <el-button type="danger" size="mini" @click="deleteRenYuan(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
		<el-pagination style="width: 100%;text-align: center;" background layout="prev, pager, next" :page-count="last_page"
		 :current-page="page" @current-change="pageChange">
		</el-pagination>
	</div>
</template>
<script>
	import tableCss from '../../../style/tableCss.js';
	import {
		mapState
	} from "vuex";
  import {getRenYuanInZhuFangListFn} from '@/api/zhuFang.js'
  import {deleteZhuHuFn} from '@/api/zhuHu.js'
	export default {
		data() {
			return {
        id:'',
				loading: false,
				
				page: 1,
				page_size: 10,
				last_page: 0,
        zhuHuList:[],
        zhuHuInfo:""
			}
		},
		computed: {
			...mapState(['loadingColor', 'loadingText'])
		},
    created() {
      this.id = this.$route.query.id
    },
    mounted() {
			this.getRenYuanInZhuFangList();
		},
		methods: {
      //删除人员
      deleteRenYuan(row){
        let params = {
          api_token : localStorage.getItem('token1'),
          id:row.id
        }
        this.$confirm('确定删除', '确定删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          deleteZhuHuFn(params).then(res => {
            this.getRenYuanInZhuFangList()
          })
        });
      },
      //去编辑人员页面
      goToEditPage(row){
        this.$router.push({
          path:'/editZhuHu',
          query:{
            // building_id:this.zhuHuInfo.other.building_id,
            // unit_id:this.zhuHuInfo.other.unit_id,
            // house_id:this.zhuHuInfo.other.house_id,
            id:row.id,
            type:'fromZhufang'
          }
        })
      },
      //去添加成员页面
      goToAdd(){
        this.$router.push({
          path:'/addZhuHu',
          query:{
            building_id:this.zhuHuInfo.other.building_id,
            unit_id:this.zhuHuInfo.other.unit_id,
            house_id:this.zhuHuInfo.other.house_id,
            id:this.id,
            type:'fromZhufang'
          }
        })
      },
      //获取住房下人员
			getRenYuanInZhuFangList() {
				this.loading = true;
				getRenYuanInZhuFangListFn({
          id:this.id,
					api_token : localStorage.getItem('token1'),
				}).then(res => {
					this.zhuHuList = res.datas.data;
          this.zhuHuInfo = res.datas
					this.last_page = res.meta.last_page;
					this.loading = false;
				})
			},
			//页数改变
			pageChange(page) {
				this.page = page;
				this.getRenYuanInZhuFangList();
			},
			//表头样式
			TableTitleBackColor() {
				return tableCss.tableTitleBackColor();
			},
			//表格样式
			tableCss() {
				return tableCss.tableCss();
			},
			//单元格样式
			cellStyle() {
				return tableCss.cellStyle();
			},
		},
		activated() {

		}
	}
</script>
<style scoped lang='scss'>
	@import url("../../../style/title.css");
  .xiaoqu-info{
    display: flex;
    justify-content: flex-start;
    padding:20px 20px 0 20px;
    div{
      margin-right: 30px;
    }
  }
</style>
